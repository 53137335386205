<template>
  <div>
    <!-- Inputs de data -->
    <b-card title="Filtrar por data">
      <b-row>
        <b-col lg="6" md="12">
          <b-form-group label="Data Inicial">
            <b-form-datepicker
              v-model="start_date"
              type="date"
              @input="getAll(), getMembersTasks(), getCashFlow()"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="12">
          <b-form-group label="Data Final">
            <b-form-datepicker
              v-model="end_date"
              type="date"
              @input="getAll(), getMembersTasks(), getCashFlow()"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="info">
            <h5 class="mb-0 ml-50 text text-white">
              Dados de planejamento
            </h5></b-button
          >
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card v-if="selectedItems.length > 0">
            <b-card-header>
              <span class="d-flex align-items-center">
                <feather-icon icon="PieChartIcon" size="20" />
                <h2 class="mb-0 ml-50">Dados de planejamento</h2>
              </span>
            </b-card-header>

            <h3 class="mb-0 ml-50">Fases do projeto</h3>
            <b-row
              class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
            >
              <b-col cols="12" xs="12" sm="12" md="12" lg="12">
                <div class="selected-items-container">
                  <ul class="item-list">
                    <li
                      v-for="(item, index) in selectedItems"
                      :key="index"
                      class="item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" class="mt-3">
                <b-form-group>
                  <label
                    for="contact_name"
                    class="text-center font-weight-bold text-primary"
                  >
                    Valor estimado da hora:
                  </label>
                  <BFormInput
                    placeholder="R$ 0,00"
                    id="contact_name"
                    type="text"
                    v-money="moneyMask"
                    v-model="prediction_data[0].hour_cost"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" class="mt-3">
                <b-form-group>
                  <label
                    for="contact_name"
                    class="text-center font-weight-bold text-primary"
                  >
                    Alocação prevista de custo direto:
                  </label>
                  <BFormInput
                    placeholder="R$ 0,00"
                    id="contact_name"
                    type="text"
                    v-money="percentageMask"
                    v-model="prediction_data[0].direct_cost_prediction"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) in selectedItems"
              :key="index"
              class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
            >
              <b-col cols="12">
                <h4 class="text-left">FASE {{ index + 1 }} - {{ item }}</h4>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label
                    for="project_value"
                    class="text-center font-weight-bold text-primary"
                  >
                    Valor previsto em horas
                  </label>
                  <b-input-group>
                    <BFormInput
                      type="text"
                      v-model="stages_data[index].stage_hour_prediction"
                      v-money="integerMask"
                      placeholder="Ex. 20h"
                      disabled
                    >
                    </BFormInput>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Valor do contrato
                  </label>
                  <BFormInput
                    type="text"
                    v-model="
                      stages_data[index].stage_predicted_direct_cost_revenue
                    "
                    v-money="moneyMask"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Alocado com custo direto
                  </label>
                  <BFormInput
                    placeholder="R$ 0,00"
                    type="text"
                    v-model="
                      stages_data[index].stage_predicted_contract_revenue
                    "
                    v-money="moneyMask"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label
                    for="contact_name"
                    class="text-center font-weight-bold text-primary"
                  >
                    Terminar etapa até:
                  </label>
                  <b-form-datepicker
                    type="date"
                    disabled
                    v-model="stages_data[index].stage_due_date"
                    :date-format-options="{
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />

            <b-card-header>
              <span class="d-flex align-items-center">
                <h3 class="mb-0 ml-50">Custos indiretos alocados</h3>
              </span>
            </b-card-header>

            <b-row
              class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
            >
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Imposto (%)
                  </label>
                  <BFormInput
                    placeholder="%"
                    type="text"
                    v-money="percentageMask"
                    v-model="prediction_data[0].tax_fee"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Retenção (%)
                  </label>
                  <BFormInput
                    placeholder="%"
                    type="text"
                    v-money="percentageMask"
                    v-model="prediction_data[0].retention_fee"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Comissão (%)
                  </label>
                  <BFormInput
                    placeholder="%"
                    type="text"
                    v-money="percentageMask"
                    v-model="prediction_data[0].comission_fee"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Administrativo (%)
                  </label>
                  <BFormInput
                    placeholder="%"
                    type="text"
                    v-money="percentageMask"
                    v-model="prediction_data[0].administration_fee"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
            >
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Imposto (R$)
                  </label>
                  <BFormInput
                    placeholder="R$"
                    type="text"
                    v-money="moneyMask"
                    v-model="prediction_data[0].tax_value"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Retenção (R$)
                  </label>
                  <BFormInput
                    placeholder="R$"
                    type="text"
                    v-money="moneyMask"
                    v-model="prediction_data[0].retention_value"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Comissão (R$)
                  </label>
                  <BFormInput
                    placeholder="R$"
                    type="text"
                    v-money="moneyMask"
                    v-model="prediction_data[0].comission_value"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Administrativo (R$)
                  </label>
                  <BFormInput
                    placeholder="R$"
                    type="text"
                    v-money="moneyMask"
                    v-model="prediction_data[0].administration_value"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
            </b-row>

            <b-card-header>
              <span class="d-flex align-items-center">
                <h3 class="mb-0 ml-50">3. Disciplinas e relatórios</h3>
              </span>
            </b-card-header>

            <b-row
              v-for="(discipline, index) in disciplines"
              :key="index"
              class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-start"
            >
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Identificação
                  </label>
                  <BFormInput
                    placeholder="Nome"
                    type="text"
                    v-model="discipline.discipline_name"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Alocado (%)
                  </label>
                  <BFormInput
                    placeholder="%"
                    type="text"
                    v-money="percentageMask"
                    v-model="discipline.percentual"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" xs="12" sm="12" md="2" lg="2">
          <b-form-group>
            <label class="text-center font-weight-bold text-primary">
              Vlr disponível (R$)
            </label>
            <BFormInput
              placeholder="R$"
              type="text"
              v-money="moneyMask"
              v-model="discipline.disponible"
              disabled
            ></BFormInput>
          </b-form-group>
        </b-col> -->
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    1º pagamento (R$)
                  </label>
                  <BFormInput
                    placeholder="R$"
                    type="text"
                    v-money="moneyMask"
                    v-model="discipline.first_payment"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    2º pagamento (R$)
                  </label>
                  <BFormInput
                    placeholder="R$"
                    type="text"
                    v-money="moneyMask"
                    v-model="discipline.second_payment"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6">
                <b-form-group>
                  <label class="text-center font-weight-bold text-primary">
                    Responsável
                  </label>
                  <BFormInput
                    placeholder="Nome"
                    type="text"
                    v-model="discipline.responsible"
                    disabled
                  ></BFormInput>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                xs="12"
                sm="12"
                md="1"
                lg="1"
                class="d-flex align-items-center justify-content-center"
              >
              </b-col>
            </b-row>
            <hr />

            <b-card-header>
              <span class="d-flex align-items-center">
                <feather-icon icon="PieChartIcon" size="20" />
                <h2 class="mb-0 ml-50">
                  Resumo da operação de contrato (projeto)
                </h2>
              </span>
            </b-card-header>
            <b-row class="mt-2 text-left d-flex justify-content-center">
              <b-col cols="12">
                <b-table
                  striped
                  hover
                  small
                  :items="table"
                  :fields="fields"
                  show-empty
                  class="w-100 p-1"
                >
                  <template #empty>
                    <b-alert show variant="primary">
                      <p class="text-center">
                        <br />
                        Aguardando os cálculos para prever resultados
                      </p>
                      <hr />
                    </b-alert>
                  </template>
                  <template v-slot:cell(predicted_revenue)="{ item }">
                    R$ {{ numberToMonetary(item.predicted_revenue) }}
                  </template>

                  <template v-slot:cell(predicted_gross_revenue)="{ item }">
                    R$ {{ numberToMonetary(item.predicted_gross_revenue) }}
                  </template>

                  <template v-slot:cell(predicted_liquid_revenue)="{ item }">
                    R$ {{ numberToMonetary(item.predicted_liquid_revenue) }}
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row v-if="disciplines && disciplines.length > 0" class="mt-5">
              <b-col cols="12" class="text-left mx-auto flex">
                <b-table
                  responsive="sm"
                  small
                  striped
                  hover
                  :items="disciplines"
                  :fields="disciplinesFields"
                  show-empty
                  class="p-1 w-100"
                  foot-clone
                >
                  <template #empty>
                    <b-alert show variant="primary">
                      <p class="text-center">
                        <br />
                        <strong>Não há dados para exibir</strong>
                      </p>
                      <hr />
                    </b-alert>
                  </template>
                  <template v-slot:cell(total)="{ item }">
                    {{
                      ` ${numberToMonetary(
                        cleanAndConvert(item.first_payment) +
                          cleanAndConvert(item.second_payment)
                      )}`
                    }}
                  </template>
                  <template #foot(first_payment)>
                    <strong
                      >R$ {{ numberToMonetary(totalFirstPayment) }}</strong
                    >
                  </template>

                  <template #foot(second_payment)>
                    <strong
                      >R$ {{ numberToMonetary(totalSecondPayment) }}</strong
                    >
                  </template>

                  <template #foot(total)>
                    <strong>R$ {{ numberToMonetary(totalSum) }}</strong>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row
              v-if="item.predicted_liquid_revenue < 0"
              class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
            >
              <b-alert show variant="danger">
                <p class="text-center">
                  <br />
                  Atenção! <br />Os parâmetros informados no planejamento do
                  projeto prevê prejuízo financeiro.
                  <strong>Fique atento aos custos diretos </strong> ou considere
                  <strong>aumentar o valor estimado da hora</strong>.
                </p>
                <br />
              </b-alert>
            </b-row>
          </b-card>
        </b-collapse>
      </b-card>
    </div>

    <b-card :title="`Painel do projeto: ${project_name} | Tipo: ${project_type}`" class="text-left">
      <b-row>
        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(cashFlow_data.chartSeries[1].data[0])}`
            }}</strong>
            <small>Custos de Projetos </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(item.projectResumeDashboard.legalExpenses)}`
            }}</strong>
            <small>Despesas Legais </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(item.projectResumeDashboard.projectRevenue)}`
            }}</strong>
            <small>Receitas do projeto </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(item.projectResumeDashboard.direct_cost)}`
            }}</strong>
            <small>Custo Direto </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(item.projectResumeDashboard.indirect_cost)}`
            }}</strong>
            <small>despesa fixa </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(
                item.projectResumeDashboard.projectTasksTotalCost
              )}`
            }}</strong>
            <small>Custo Total (Tarefas) </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(
                item.projectResumeDashboard.projectTotalCost
              )}`
            }}</strong>
            <small>Custo total do Projeto </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(
                item.projectResumeDashboard.contributionMargin
              )}`
            }}</strong>
            <small>Margem de contribuição</small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `${numberToMonetary(item.projectResumeDashboard.projectResult)}`
            }}</strong>
            <small>Resultado </small>
          </div>
        </b-col>

        <!-- <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{ item.projectResumeDashboard.profitability }}</strong>
            <small>Lucratividade </small>
          </div>
        </b-col> -->

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{ item.projectResumeDashboard.hours }}</strong>
            <small>Horas no projeto </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{ item.projectResumeDashboard.taskAmount }}</strong>
            <small>Tarefas no projeto </small>
          </div>
        </b-col>
        <b-col cols="12" lg="12">
          <div class="dashboard-item">
            <strong>{{
              table && table[0]
                ? ` ${numberToMonetary(
                    item.projectResumeDashboard.projectResult -
                      table[0].predicted_liquid_revenue
                  )}`
                : 0
            }}</strong>
            <small>Desvio do orçamento </small>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card :title="`Quadro de tarefas`" class="text-left">
      <b-row class="d-flex justify-content-around">
        <div
          class="dashboard-item text-left"
          style="border: 1px #00000055 solid"
        >
          <strong style="font-size: 30px">{{ `${totalTasks}` }}</strong>
          <small>Total de tarefas do projeto </small>
          <div
            class="dashboard-item-row text-left"
            style="border: 1px #00000055 solid; margin-top: 15px"
          >
            <b-badge variant="danger" class="mr-2">
              🔥 Alta:
              {{ taskPriority && taskPriority.Alta ? taskPriority.Alta : 0 }}
            </b-badge>
            <b-badge variant="warning" class="mr-2">
              ⚠️ Média:
              {{ taskPriority && taskPriority.Media ? taskPriority.Media : 0 }}
            </b-badge>
            <b-badge variant="success">
              ✅ Baixa:
              {{ taskPriority && taskPriority.Baixa ? taskPriority.Baixa : 0 }}
            </b-badge>
          </div>

          <div
            class="dashboard-item-row text-left"
            style="border: 1px #00000055 solid; margin-top: 5px"
          >
            <b-badge variant="primary" class="mr-2">
              Ativa:
              {{
                taskStatus && taskStatus['EM EXECUÇÃO']
                  ? taskStatus['EM EXECUÇÃO']
                  : 0
              }}
            </b-badge>
            <b-badge variant="primary" class="mr-2">
              Em pausa:
              {{
                taskStatus && taskStatus['EM PAUSA']
                  ? taskStatus['EM PAUSA']
                  : 0
              }}
            </b-badge>
            <b-badge variant="primary" class="mr-2">
              Concluída:
              {{
                taskStatus && taskStatus['CONCLUÍDA']
                  ? taskStatus['CONCLUÍDA']
                  : 0
              }}
            </b-badge>
            <!-- <b-badge variant="primary">
              Cancelada:
              {{
                taskStatus && taskStatus['CANCELADA']
                  ? taskStatus['CANCELADA']
                  : 0
              }}
            </b-badge> -->
          </div>
        </div>

        <div class="dashboard-item text-left" style="width: 55% !important">
          <label for="">Tarefas</label>
          <ul class="item-list">
            <li
              v-for="task in taskList"
              :key="task.task_id"
              class="item"
              v-b-tooltip.hover
              :title="`${task.project} - ${
                task && task.stage ? task.stage.stage_name : ''
              }`"
              @click="openModal(task)"
            >
              <strong>
                {{
                  task.priority === 'Baixa'
                    ? '✅'
                    : task.priority === 'Media'
                    ? '⚠️'
                    : task.priority === 'Alta'
                    ? '🔥'
                    : '❓'
                }}
                {{ task.task_name }}
              </strong>
              <strong>{{ task.task_name }}</strong>
              <span>
                | Prioridade: {{ task.priority }} | Status da tarefa:
                {{ task.status }}
              </span>
            </li>
          </ul>
        </div>
      </b-row>
    </b-card>

    <b-card :title="`Fluxo financeiro do projeto`" class="text-left">
      <b-row>
        <b-col cols="12" class="text-center mx-auto flex">
          <h4 class="mb-1 text-left"><strong>Receitas</strong></h4>
          <b-table
            responsive="sm"
            small
            striped
            hover
            :items="revenueTotals"
            :fields="revenueFieldsTotal"
            show-empty
            class="p-1 w-100 table-details table-success mb-2"
          >
            <template #empty>
              <b-alert show variant="primary">
                <p class="text-center">
                  <br />
                  <strong>Não há dados para exibir</strong>
                </p>
                <hr />
              </b-alert>
            </template>
            <template v-slot:cell(totalRealizadoReceita)="{ item }">
              {{ ` ${numberToMonetary(item.totalRealizadoReceita)}` }}
            </template>
            <template v-slot:cell(totalPrevistoReceita)="{ item }">
              {{ ` ${numberToMonetary(item.totalPrevistoReceita)}` }}
            </template>
            <template v-slot:cell(totalGeralReceita)="{ item }">
              {{ ` ${numberToMonetary(item.totalGeralReceita)}` }}
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" class="text-center mx-auto">
          <h4 class="mb-1 text-left"><strong>Despesas</strong></h4>
          <b-table
            responsive="sm"
            small
            striped
            hover
            :items="expenseTotals"
            :fields="expenseFieldsTotal"
            show-empty
            class="p-1 w-100 table-details table-danger"
          >
            <template #empty>
              <b-alert show variant="primary">
                <p class="text-center">
                  <br />
                  <strong>Não há dados para exibir</strong>
                </p>
                <hr />
              </b-alert>
            </template>
            <template v-slot:cell(totalRealizadoDespesa)="{ item }">
              {{ ` ${numberToMonetary(item.totalRealizadoDespesa)}` }}
            </template>
            <template v-slot:cell(totalPrevistoDespesa)="{ item }">
              {{ ` ${numberToMonetary(item.totalPrevistoDespesa)}` }}
            </template>
            <template v-slot:cell(totalGeralDespesa)="{ item }">
              {{ ` ${numberToMonetary(item.totalGeralDespesa)}` }}
            </template>
          </b-table>
        </b-col>

        <!-- <b-col lg="8" md="12" class="text-center mx-auto">
          <ProfitabilityChart :chartData="drp_data" />
        </b-col> -->

        <b-col v-if="cashFlow_data" class="text-center mx-auto mt-2" cols="12">
          <CashFlow type="line" height="400" :chartData="cashFlow_data" />
        </b-col>

        <b-col lg="8" md="12" class="text-center mx-auto">
          <!-- item.projectResumeDashboard.projectResult -->
          <b-alert show variant="primary" class="p-1">
            <p class="text-center">
              O projeto {{ project_name }} teve um resultado de
              <strong v-if="item.projectResumeDashboard.projectResult > 0"
                >lucro</strong
              >
              <strong
                v-else-if="item.projectResumeDashboard.projectResult === 0"
                >Ponto de equilíbrio</strong
              >
              <strong v-else>prejuízo</strong>
              para o período escolhido.
            </p>

            <p>
              <span v-if="item.projectResumeDashboard.projectResult > 0">
                Procure manter as receitas superando as despesas.
              </span>
              <span v-else-if="item.projectResumeDashboard.projectResult === 0">
                As despesas e receitas se anulam.
              </span>
              <span v-else>
                Recomenda-se aguardar receitas para continuar o progresso.
              </span>
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-card-footer class="text-right font-weight-bold">
        Saldo Final:
        <span :class="totalBalance >= 0 ? 'text-success' : 'text-danger'">
          R$ {{ numberToMonetary(totalBalance) }}
        </span>
      </b-card-footer>
    </b-card>

    <!-- Lucratividade -->

    <b-card
      v-if="profitability_data.render"
      :title="`Lucratividade`"
      class="text-left"
      v-show="false"
    >
      <b-row>
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <ProfitabilityChart
            v-if="profitability_data.render"
            :chartData="profitability_data"
        /></b-col>
      </b-row>
    </b-card>

    <!-- Receitas e Despesas -->

    <b-card
      v-if="
        revenue_data.render ||
        projectCost_data.render ||
        hoursMonth_data.render ||
        costsMonth_data.render ||
        hoursPerMember_data.render ||
        costsPerMember_data.render
      "
      :title="`Indicadores`"
      class="text-left"
    >
      <b-row class="mb-4">
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <label>Receitas</label>
          <ProfitabilityChart
            v-if="revenue_data.render"
            :chartData="revenue_data"
        /></b-col>

        <b-col cols="12" md="6" lg="4" class="text-center mx-auto flex">
          <label>Despesas (Custos de Projeto)</label>
          <ProfitabilityChart
            v-if="projectCost_data.render"
            :chartData="projectCost_data"
        /></b-col>
      </b-row>

      <!-- Horas Mes e  Custo de tarefas (Direto e Indireto)-->
      <b-row class="mb-4">
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <label>Horas mes</label>
          <ProfitabilityChart
            v-if="hoursMonth_data.render"
            :chartData="hoursMonth_data"
        /></b-col>

        <b-col cols="12" md="6" lg="4" class="text-center mx-auto flex">
          <label>Custos de tarefas</label>
          <ProfitabilityChart
            v-if="costsMonth_data.render"
            :chartData="costsMonth_data"
        /></b-col>
      </b-row>

      <!-- Horas por Colaborador no periodo e Custo Direto + Indireto por colaborador -->
      <b-row>
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <label>Horas por colaborador no período</label>
          <ProfitabilityChart
            v-if="hoursPerMember_data.render"
            :chartData="hoursPerMember_data"
        /></b-col>

        <b-col cols="12" md="6" lg="4" class="text-center mx-auto flex">
          <label>Custo Direto + Indireto por colaborador</label>
          <ProfitabilityChart
            v-if="costsPerMember_data.render"
            :chartData="costsPerMember_data"
        /></b-col>
      </b-row>
    </b-card>



    <b-card
      v-if="
        item && item.groupedStagesArray
          ? item.groupedStagesArray.length > 0
          : false
      "
      title="Tarefas por fase do projeto"
    >
      <b-row>
        <b-col cols="12" lg="4" class="text-center mx-auto flex">
          <label>FASE</label>
          <div class="selected-items-container">
            <ul class="item-list">
              <li
                v-for="(stage, index) in item.groupedStagesArray"
                :key="index"
                class="item"
                :style="{ backgroundColor: stage.color }"
              >
                {{ stage.stage_name }}
              </li>
            </ul>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="text-center mx-auto flex">
          <label>QUANTIDADE DE TAREFAS</label>
          <div class="selected-items-container">
            <ul class="item-list">
              <li
                v-for="(stage, index) in item.groupedStagesArray"
                :key="index"
                class="item"
                v-b-tooltip.hover.lefttop.v-primary
                :title="
                  'Resposáveis: ' +
                  stage.tasks.map((task) => task.member).join(', ')
                "
                :style="{ backgroundColor: stage.color }"
              >
                {{ stage.task_count }}
              </li>
            </ul>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="text-center mx-auto flex">
          <label>HORAS NA FASE</label>
          <div class="selected-items-container">
            <ul class="item-list">
              <li
                v-for="(stage, index) in item.groupedStagesArray"
                :key="index"
                class="item"
                :style="{ backgroundColor: stage.color }"
              >
                {{ stage.tasks.reduce((sum, task) => sum + task.hours, 0) }}h
              </li>
            </ul>
          </div>
        </b-col>
        <b-col class="mr-2 mt-1 d-flex justify-content-end text-end">
          <strong class="mr-1"
            >Total de tarefas: {{ item.projectResumeDashboard.taskAmount }}
          </strong>
          <strong
            >Total de horas: {{ item.projectResumeDashboard.hours }}h
          </strong>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="
        item && item.groupedTasksArray
          ? item.groupedTasksArray.length > 0
          : false
      "
      title="Tarefas por tipo"
    >
      <b-row>
        <b-col cols="12" lg="4" class="text-center mx-auto flex">
          <label>TITULO DA TAREFA</label>
          <div class="selected-items-container">
            <ul class="item-list">
              <li
                v-for="(task, index) in item.groupedTasksArray"
                :key="index"
                class="item"
                :style="{ backgroundColor: task.tasks[0].color }"
              >
                {{ task.task_title }}
              </li>
            </ul>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="text-center mx-auto flex">
          <label>PERTENCE A(S) FASE(S)</label>
          <div class="selected-items-container">
            <ul class="item-list">
              <li
                v-for="(task, index) in item.groupedTasksArray"
                :key="index"
                class="item"
                v-b-tooltip.hover.lefttop.v-primary
                :title="
                  'Fase: ' + task.tasks.map((task) => task.stage).join(', ')
                "
                :style="{ backgroundColor: task.tasks[0].color }"
              >
                {{ task.tasks.map((task) => task.stage).join(', ') }}
              </li>
            </ul>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="text-center mx-auto flex">
          <label>HORAS NA TEREFA</label>
          <div class="selected-items-container">
            <ul class="item-list">
              <li
                v-for="(task, index) in item.groupedTasksArray"
                :key="index"
                class="item"
                :style="{ backgroundColor: task.tasks[0].color }"
              >
                {{ task.tasks.reduce((sum, task) => sum + task.hours, 0) }}h
              </li>
            </ul>
          </div>
        </b-col>
        <b-col class="mr-2 mt-1 d-flex justify-content-end text-end">
          <strong class="mr-1"
            >Total de tarefas: {{ item.projectResumeDashboard.taskAmount }}
          </strong>
          <strong
            >Total de horas: {{ item.projectResumeDashboard.hours }}h
          </strong>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip,
  VBToggle,
  BCol,
  BRow,
  BCard,
  BFormGroup,
  BFormDatepicker,
  BCardHeader,
  BTable,
  BAlert,
  BFormInput,
  BInputGroup,
  BCollapse,
  BButton,
  BBadge,
  BCardFooter,
} from 'bootstrap-vue';
import CashFlow from '@/components/CashFlow.vue';

import ProfitabilityChart from '../charts/Profitability.vue';
import moment from 'moment';

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  components: {
    BCol,
    BRow,
    BCard,
    ProfitabilityChart,
    BFormGroup,
    BFormDatepicker,
    BTable,
    BAlert,
    BCardHeader,
    BFormInput,
    BInputGroup,
    BCollapse,
    BButton,
    BBadge,
    CashFlow,
    BCardFooter,
  },
  data: () => ({
    expenseTotals: [],
    revenueTotals: [],
    selectedItems: [],
    cashFlowResp: [],
    cashFlow_data: { render: false },

    taskList: [],
    totalTasks: '',
    taskStatus: [],
    taskPriority: {},
    disciplines: [],
    item: {
      projectResumeDashboard: {
        projectCost: 0,
        legalExpenses: 0,
        projectRevenue: 0,
        direct_cost: 0,
        indirect_cost: 0,
        projectTasksTotalCost: 0,
        projectTotalCost: 0,
        contributionMargin: 0,
        projectResult: 0,
        profitability: 0,
        hours: 0,
      },
    },
    prediction_data: [],
    stages_data: {
      stage_hour_prediction: [],
      stage_predicted_contract_revenue: [],
      stage_predicted_direct_cost_revenue: [],
      stage_name: [],
      stage_due_date: [],
    },
    project_name: 'Projeto',
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
    profitability_data: { render: false },
    revenue_data: { render: false },
    projectCost_data: { render: false },
    drp_data: { render: false },
    hoursMonth_data: { render: false },
    costsMonth_data: { render: false },

    hoursPerMember_data: { render: false },
    costsPerMember_data: { render: false },
    expenseFieldsTotal: [
      {
        key: 'totalPrevistoDespesa',
        label: 'A PAGAR',
        sortable: false,
      },
      {
        key: 'totalRealizadoDespesa',
        label: 'PAGO',
        sortable: false,
      },
      {
        key: 'totalGeralDespesa',
        label: 'DESPESAS TOTAIS',
        sortable: false,
      },
    ],

    revenueFieldsTotal: [
      {
        key: 'totalPrevistoReceita',
        label: 'A RECEBER',
        sortable: false,
      },
      {
        key: 'totalRealizadoReceita',
        label: 'RECEBIDO',
        sortable: false,
      },
      {
        key: 'totalGeralReceita',
        label: 'Receitas totais',
        sortable: false,
      },
    ],
    disciplinesFields: [
      {
        key: 'discipline_name',
        label: 'Identificador',
        sortable: false,
      },
      {
        key: 'responsible',
        label: 'Responsável',
        sortable: false,
      },
      {
        key: 'first_payment',
        label: '1º Pagamento',
        sortable: false,
      },
      {
        key: 'second_payment',
        label: '2º Pagamento',
        sortable: false,
      },
      {
        key: 'total',
        label: 'Total',
        sortable: false,
      },
    ],

    chartOptions: {
      chart: {
        id: 'bar',
        type: 'bar',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (number) {
            if (isNaN(number)) {
              return 'Invalid input';
            }
            if (number === 0) {
              return 'R$ 0,00';
            }
            if (number < 100) {
              const formattedCents = number.toFixed(2).padStart(5, '0');
              return `0,${formattedCents}`;
            }
            const [integerPart, decimalPart] = (number / 100)
              .toFixed(2)
              .toString()
              .split('.');
            const formattedIntegerPart = integerPart.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            );
            const result = ` ${formattedIntegerPart},${decimalPart}`;
            return result;
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 40,
        },
      },

      dataLabels: {
        enabled: true,
        formatter: function (number) {
          if (isNaN(number)) {
            return 'Invalid input';
          }
          if (number === 0) {
            return 'R$ 0,00';
          }
          if (number < 100) {
            const formattedCents = number.toFixed(2).padStart(5, '0');
            return `0,${formattedCents}`;
          }
          const [integerPart, decimalPart] = (number / 100)
            .toFixed(2)
            .toString()
            .split('.');
          const formattedIntegerPart = integerPart.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ','
          );
          const result = ` ${formattedIntegerPart},${decimalPart}`;
          return result;
        },
        offsetY: -20,
        style: {
          fontSize: '10px',
          colors: ['#304758'],
        },
      },
    },

    chartOptions_line: {
      chart: {
        id: 'bar',
        type: 'area',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 20,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
    },
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: true,
    },
    percentageMask: {
      prefix: '% ',
      suffix: '',
      thousands: '',
      decimal: ',',
      precision: 1,
      masked: false,
    },
    integerMask: {
      prefix: '',
      suffix: 'h',
      thousands: '',
      decimal: '',
      precision: 0,
      masked: false,
    },
    fields: [
      {
        key: 'predicted_revenue',
        label: 'Receita total',
        sortable: false,
      },
      {
        key: 'predicted_gross_revenue',
        label: 'Lucro bruto',
        sortable: false,
      },
      {
        key: 'predicted_liquid_revenue',
        label: 'Lucro líquido',
        sortable: false,
      },
    ],
    table: [],
  }),

  created() {
    this.getAll();
    this.getMembersTasks();
    this.getCashFlow();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    totalFirstPayment() {
      return this.disciplines.reduce(
        (sum, item) => sum + this.cleanAndConvert(item.first_payment),
        0
      );
    },

    totalSecondPayment() {
      return this.disciplines.reduce(
        (sum, item) => sum + this.cleanAndConvert(item.second_payment),
        0
      );
    },
    totalSum() {
      return this.disciplines.reduce((sum, item) => {
        return (
          sum +
          this.cleanAndConvert(item.first_payment) +
          this.cleanAndConvert(item.second_payment)
        );
      }, 0);
    },
    totalBalance() {
      const revenue = this.revenueTotals.reduce(
        (sum, item) => sum + item.totalGeralReceita,
        0
      );
      const expense = this.expenseTotals.reduce(
        (sum, item) => sum + item.totalGeralDespesa,
        0
      );

      console.log('this.revenueTotals', this.revenueTotals);
      return revenue - expense;
    },
  },

  methods: {
    async getCashFlow() {
      this.cashFlowResp = [];
      this.$store
        .dispatch('getCashFlowDashboard', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          start_date: this.start_date,
          end_date: this.end_date,
          project_id: this.$route.params.id,
        })
        .then((resp) => {
          this.cashFlowResp = resp;
          this.setCashFlow_data();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cleanAndConvert(value) {
      if (!value) return 0;
      const cleanedValue = value.replace(/[R$\s.,h%]/g, '').replace(',', '.');
      return parseInt(cleanedValue) || 0;
    },
    async getItem() {
      this.table = [];
      try {
        const resp = await this.$store.dispatch('getProject', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        });

        this.project_name = resp.project_name;
        this.project_type = resp.project_type && resp.project_type.name ?  resp.project_type.name : 'Não informado'
        this.selectedItems = resp.stages
          ? resp.stages.map((stage) => stage.stage_name)
          : null;

        this.table.push({
          predicted_revenue: resp.project_value,
          predicted_gross_revenue:
            resp.project_value -
            resp.stages[0].stage_predicted_contract_revenue *
              resp.stages.length,

          predicted_liquid_revenue:
            resp.project_value -
            resp.stages[0].stage_predicted_contract_revenue *
              resp.stages.length -
            resp.tax_value -
            resp.retention_value -
            resp.comission_value -
            resp.administration_value,
        });

        if (resp.stages.length > 0) {
          this.stages_data = resp.stages.map((stage) => ({
            stage_name: stage.stage_name,
            stage_hour_prediction: stage.stage_hour_prediction,
            stage_predicted_contract_revenue: this.numberToMonetary(
              stage.stage_predicted_contract_revenue
            ),
            stage_predicted_direct_cost_revenue: this.numberToMonetary(
              stage.stage_predicted_direct_cost_revenue
            ),
            stage_due_date: stage.stage_due_date,
          }));
        }
        this.prediction_data.push({
          hour_cost: resp.hour_cost,
          direct_cost_prediction: resp.direct_cost_prediction,
          tax_fee: resp.tax_fee,
          retention_fee: resp.retention_fee,
          comission_fee: resp.comission_fee,
          administration_fee: resp.administration_fee,
          tax_value: resp.tax_value,
          retention_value: resp.retention_value,
          comission_value: resp.comission_value,
          administration_value: resp.administration_value,
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getAll() {
      (this.expenseTotals = []),
        (this.revenueTotals = []),
        this.$store
          .dispatch('getProjectDashboard', {
            start_date: this.start_date,
            end_date: this.end_date,
            project_id: this.$route.params.id,
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then(async (resp) => {
            this.item = resp;
            (this.disciplines =
              resp && resp.disciplines ? resp.disciplines : null),
              this.expenseTotals.push(resp.projectCost);
            this.revenueTotals.push(resp.revenueTotals);

            this.getItem();

            await this.setProfitabilityChart(resp.monthlyProfitability);

            await this.setRevenueChart(resp.revenues);
            await this.setProjectCostChart(resp.projectCost2);
            await this.setDrpChart(
              resp.revenueTotals.totalGeralReceita,
              resp.expenseTotals.totalGeralDespesa
            );

            await this.setHoursMonthChart(resp.tasksByMonth);
            await this.setCostsMonthChart(resp.tasksByMonth);

            await this.setHoursPerMemberChart(resp.tasksCostAndHours);
            await this.setCostPerMemberChart(resp.tasksCostAndHours);

            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getMembersTasks() {
      this.$store
        .dispatch('getUserDashboard', {
          start_date: this.start_date,
          end_date: this.end_date,
          id: null,
          workspace_id: this.$store.getters.currentWorkspace.id,
          allPeriod: this.allPeriod == true ? true : false,
          projectDashboard: true,
          project_id: this.$route.params.id,
        })
        .then(async (resp) => {
          this.taskList = resp.taskList;
          this.totalTasks = resp.totalTasks;
          this.taskPriority = resp.taskPriority;
          this.taskStatus = resp.taskStatus;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Lucratividade
    async setProfitabilityChart(monthlyProfitability) {
      const profitability = [];
      const date = [];
      monthlyProfitability.map((e) => {
        profitability.push(e.profitability);
        date.push(this.dateFormatter(e.date, 'MM/yy'));
      });

      this.profitability_data = {
        render: !profitability.length ? false : true,
        chartOptions: {
          ...this.chartOptions_line,

          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Custos do projeto',
            data: profitability,
          },
        ],
      };
    },

    // Receitas
    async setRevenueChart(revenues) {
      console.log(revenues);
      const data = [];
      const date = [];
      revenues.map((e) => {
        data.push(e.projectRevenue);
        date.push(this.dateFormatter(e.transaction_date, 'MM/yy'));
      });

      this.revenue_data = {
        render: data.length == 0 ? false : true,
        chartOptions: {
          ...this.chartOptions,
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Receitas',
            data,
          },
        ],
      };
    },

    // Despesas (Custos de Projeto)
    async setProjectCostChart(costs) {
      const data = [];
      const date = [];
      costs.map((e) => {
        data.push(e.value);
        date.push(this.dateFormatter(e.transaction_date, 'MM/yy'));
      });

      this.projectCost_data = {
        render: data.length == 0 ? false : true,
        chartOptions: {
          ...this.chartOptions,

          xaxis: {
            categories: date,
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return `R$ ${this.numberToMonetary(value)}`;
              },
            },
          },
          tooltip: {
            y: {
              formatter: (value, { dataPointIndex }) => {
                const originalValue = costs[dataPointIndex].value;
                const supplier =
                  costs[dataPointIndex].supplier &&
                  costs[dataPointIndex].supplier.supplier_name
                    ? costs[dataPointIndex].supplier &&
                      costs[dataPointIndex].supplier.supplier_name
                    : 'Não Informado';
                const formattedValue = `R$ ${this.numberToMonetary(
                  originalValue
                )}`;

                const transactionDate = this.dateFormatter(
                  costs[dataPointIndex].transaction_date,
                  'dd/mm/yyyy'
                );

                return `Fornecedor: ${supplier} <br> Data: ${transactionDate} <br> Valor: ${formattedValue}`;
              },
            },
          },
        },
        chartSeries: [
          {
            name: '',
            data,
          },
        ],
      };
    },

    async setDrpChart(expense, revenue) {
      const data = [];

      data.push(expense, revenue);

      this.drp_data = {
        render: !data.length ? false : true,
        chartOptions: {
          ...this.chartOptions,
          chart: {
            ...this.chartOptions.chart,
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const dataIndex = config.dataPointIndex;

                if (dataIndex == 0) {
                  this.$router.push('/lancamento-de-receita');
                }
                if (dataIndex == 1) {
                  this.$router.push('/lancamento-de-despesa');
                }
              },
            },
          },

          xaxis: {
            categories: ['RECEITAS', 'DESPESAS'],
          },
        },
        chartSeries: [
          {
            name: 'DRP',
            data,
          },
        ],
      };
    },

    // Horas X Mes
    async setHoursMonthChart(value) {
      const data = [];
      const date = [];
      value.map((e) => {
        data.push(e.hours);
        date.push(this.dateFormatter(e.date, 'MMM/yy'));
      });

      this.hoursMonth_data = {
        render: !data.length ? false : true,
        chartOptions: {
          chart: {
            id: 'hours_month',
            type: 'bar',
          },
          xaxis: {
            categories: date,
          },
          plotOptions: {
            bar: {
              distributed: true,
              dataLabels: {
                orientation: 'vertical',
              },
              columnWidth: 20,
            },
          },
        },
        chartSeries: [
          {
            data,
          },
        ],
      };
    },

    // Custo direto por colaborador
    async setCostsMonthChart(value) {
      const data = [];
      const date = [];
      value.map((e) => {
        data.push(e.cost);
        date.push(this.dateFormatter(e.date, 'MM/yy'));
      });

      this.costsMonth_data = {
        render: !data.length ? false : true,
        chartOptions: {
          ...this.chartOptions,
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Custo de projeto',
            data,
          },
        ],
      };
    },

    // Horas por colobadorador
    async setHoursPerMemberChart(value) {
      const groupedData = this.groupDataByDateAndUser_hours(value);

      const series = Object.keys(groupedData).map((username) => ({
        name: username,
        data: Object.values(groupedData[username]),
      }));

      const data = [];
      const date = [];
      series.map((e) => {
        data.push(e.data[0]);
        date.push(e.name);
      });

      this.hoursPerMember_data = {
        render: date.length > 0,

        chartOptions: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '50px',
              distributed: true,
            },
            dataLabels: {
              hideOverflowingLabels: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              formatter: function (number) {
                return Math.floor(number);
              },
            },
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas',
            data,
          },
        ],
      };
    },

    // Custo por colobadorador
    async setCostPerMemberChart(value) {
      const groupedData = this.groupDataByDateAndUser_cost(value);

      const series = Object.keys(groupedData).map((username) => ({
        name: username,
        cost: Object.values(groupedData[username]),
      }));

      const cost = [];
      const name = [];
      series.map((e) => {
        cost.push(e.cost[0]);
        name.push(e.name);
      });

      this.costsPerMember_data = {
        render: series.length > 0,
        chartOptions: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '50px',
              distributed: true,
            },
            dataLabels: {
              hideOverflowingLabels: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100)
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = ` ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
          },
          xaxis: {
            labels: {
              formatter: function (number) {
                return Math.floor(number);
              },
            },
            categories: name,
          },
        },
        chartSeries: [
          {
            name: 'Custo',
            data: cost,
          },
        ],
      };
    },

    groupDataByDateAndUser_hours(data) {
      const groupedData = {};

      data.forEach((item) => {
        if (!groupedData[item.username]) {
          groupedData[item.username] = {};
        }

        const date = new Date(item.date).toISOString().split('T')[0];

        if (!groupedData[item.username][date]) {
          groupedData[item.username][date] = 0;
        }

        groupedData[item.username][date] += item.hours;
      });

      return groupedData;
    },

    groupDataByDateAndUser_cost(data) {
      const groupedData = {};

      data.forEach((item) => {
        if (!groupedData[item.username]) {
          groupedData[item.username] = {};
        }

        const date = new Date(item.date).toISOString().split('T')[0];

        if (!groupedData[item.username][date]) {
          groupedData[item.username][date] = 0;
        }

        groupedData[item.username][date] += item.cost;
      });

      return groupedData;
    },

    async setCashFlow_data() {
      this.cashFlow_data = {
        render: true,
        width: '100%',
        height: 400,

        chartOptions: {
          chart: {
            type: 'line',
            stacked: true,
          },
          xaxis: {
            categories: this.cashFlowResp.categories,
            labels: {
              formatter: function (value) {
                return moment(value, 'YYYY-MM').format('MMM YYYY'); // Exemplo: "Abr 2024"
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                let value = val / 100;
                return `R$ ${value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`;
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (val) {
                let value = val / 100;
                return `R$ ${value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`;
              },
            },
          },
          colors: ['#28a745', '#dc3545', '#ffd147', '#6c757d'], // Adicionando cor para Outras Despesas
          stroke: {
            width: [0, 0, 0, 3], // Linha apenas para o saldo
          },
          markers: {
            size: [0, 0, 0, 5], // Marcador apenas no saldo
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              let value = val / 100;
              return `R$ ${value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`;
            },
          },
        },
        chartSeries: this.cashFlowResp.chartSeries,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-item {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: center;
}
.dashboard-item-row {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: start;
}
.table-details {
  max-height: 70vh;
  overflow-y: auto;
}
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li:hover {
  color: rgb(0, 38, 87);
}

.frame {
  border: 1px rgb(165, 165, 165) solid;
  border-radius: 5px;
  padding: 10px;
}

.selected-items-container {
  width: 100%;
}

.header {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.item-list {
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  padding: 0.5rem;
  height: 10rem;
  overflow-y: auto;

  .placeholder {
    font-style: italic;
    color: #888;
  }

  .item {
    position: relative;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 0.25rem;
    background-color: #f9f9f9;
    font-size: 10px;
    color: #000;

    &:hover {
      background-color: #e9e9e9;
    }

    .pill {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      background-color: #e74c3c;
      color: #fff;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #c0392b;
      }
    }
  }
}
</style>
