<template>
  <div>
    <apexchart
    type="line"
      height="400"
      :options="chartData.chartOptions"
      :series="chartData.chartSeries"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  data() {
    return {
      chartKey: 0,
    };
  },
  components: {
    apexchart: VueApexCharts,
  },

  watch: {
    chartData: {
      handler() {
        this.chartKey += 1;
      },
      deep: true,
    },
  },
  props: {
    chartData: {
      required: true,
    },
  },
};
</script>
